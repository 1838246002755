import tripRequestApi from '@/apis/tripRequest';
import approvalApi from '@/apis/approval';

export const GET_TRIP_REQUESTS = 'getTripRequests';
export const GET_TRIP_REQUEST = 'getTripRequest';
export const GET_TRIP_REQUEST_COMPLETE_STATUS = 'getTripRequestCompleteStatus';
export const SAVE_TRIP_REQUEST = 'saveTripRequest';
export const DELETE_TRIP_REQUEST = 'deleteTripRequest';
export const DELETE_ALL_TRIP_REQUESTS = 'deleteAllTripRequests';
export const RESET_APPROVAL = 'resetApproval';
export const PRINT_TRIP = 'printTrip';
export const PRINT_TRIPS = 'printTrips';
export const EXPORT_TRIPS = 'exportTrips';

export const GET_TRIP_REQUEST_STOPS = 'getTripRequestStops';
export const SAVE_TRIP_REQUEST_STOP = 'saveTripRequestStop';
export const DELETE_TRIP_REQUEST_STOP = 'deleteTripRequestStop';

export const SUBMIT_TRIP_REQUEST = 'submitTripRequest';
export const CHANGE_TRIP_REQUEST_SUBMITTER = 'changeSubmitter';
export const CANCEL_TRIPS = 'cancelTrips';
export const REINSTATE_TRIP = 'reinstateTrip';
export const RESCHEDULE_TRIP = 'rescheduleTrip';
export const DUPLICATE_TRIP_REQUEST = 'duplicateTripRequest';
export const APPROVE_TRIP_REQUEST = 'approveTripRequest';
export const UPDATE_ASSIGNMENT_LOCATION = 'updateAssignmentLocation';

export const SAVE_TRIP_REQUEST_COMMENT = 'saveTripRequestComment';
export const SEND_TRIP_REQUEST_EMAIL = 'sendTripRequestEmail';

export const REQUEST_QUOTE = 'requestQuote';

export const SAVE_TRIP_RECURRENCE = 'saveTripRecurrence';

export const DOWNLOAD_TRIP_TICKET = 'downloadTripTicket';
export const UPLOAD_TRIP_TICKET_TEMPLATE = 'uploadTripTicketTemplate';
export const DOWNLOAD_TRIP_TICKET_TEMPLATE = 'downloadTripTicketTemplate';

export const DOWNLOAD_PERMISSION_SLIP = 'downloadPermissionSlip';
export const UPLOAD_PERMISSION_SLIP_TEMPLATE = 'uploadPermissionSlipTemplate';
export const DOWNLOAD_PERMISSION_SLIP_TEMPLATE = 'downloadPermissionSlipTemplate';

export const DOWNLOAD_TRIP_ESTIMATE = 'downloadTripEstimate';

export const actions = {
  async [GET_TRIP_REQUESTS]({ commit }, options) {
    commit('isLoadingTrips', true);
    const tripRequests = await tripRequestApi.getTripRequests(options);
    commit('setTripRequests', tripRequests);
    commit('isLoadingTrips', false);
  },
  async [GET_TRIP_REQUEST]({ commit }, id) {
    const result = await tripRequestApi.getTripRequest(id);
    return result;
  },
  async [GET_TRIP_REQUEST_COMPLETE_STATUS](_, tripRequest) {
    const result = await tripRequestApi.getTripRequestCompleteStatus(tripRequest);
    return result;
  },
  async [SAVE_TRIP_REQUEST](_, tripRequest) {
    return await tripRequestApi.saveTripRequest(tripRequest);
  },
  async [DELETE_TRIP_REQUEST](_, ids) {
    return await tripRequestApi.deleteTripRequest(ids);
  },
  async [DELETE_ALL_TRIP_REQUESTS]() {
    return await tripRequestApi.deleteAllTripRequests();
  },
  async [RESET_APPROVAL](_, request) {
    return await tripRequestApi.resetApproval(request);
  },
  async [PRINT_TRIP](_, id) {
    return await tripRequestApi.printTrip(id);
  },
  async [PRINT_TRIPS](_, params) {
    return await tripRequestApi.printTrips(params);
  },
  async [EXPORT_TRIPS](_, params) {
    return await tripRequestApi.exportTrips(params);
  },

  async [GET_TRIP_REQUEST_STOPS]() {
    return await tripRequestApi.getTripRequestStops();
  },
  async [SAVE_TRIP_REQUEST_STOP](_, stop) {
    return await tripRequestApi.saveTripRequestStop(stop);
  },
  async [DELETE_TRIP_REQUEST_STOP](_, id) {
    return await tripRequestApi.deleteTripRequestStop(id);
  },

  async [SUBMIT_TRIP_REQUEST](_, id) {
    return await tripRequestApi.submitTripRequest(id);
  },
  async [CHANGE_TRIP_REQUEST_SUBMITTER](_, obj) {
    return await tripRequestApi.changeSubmitter(obj);
  },
  async [CANCEL_TRIPS](_, obj) {
    return await tripRequestApi.cancelTrips(obj);
  },
  async [REINSTATE_TRIP](_, id) {
    return await tripRequestApi.reinstateTrip(id);
  },
  async [RESCHEDULE_TRIP](_, obj) {
    return await tripRequestApi.rescheduleTrip(obj);
  },
  async [DUPLICATE_TRIP_REQUEST](_, id) {
    return await tripRequestApi.duplicateTripRequest(id);
  },
  async [APPROVE_TRIP_REQUEST](_, params) {
    return await approvalApi.approve(params);
  },
  async [UPDATE_ASSIGNMENT_LOCATION](_, obj) {
    return await tripRequestApi.updateAssignmentLocation(obj);
  },

  async [SAVE_TRIP_REQUEST_COMMENT](_, comment) {
    return await tripRequestApi.saveTripRequestComment(comment);
  },
  async [SEND_TRIP_REQUEST_EMAIL](_, email) {
    return await tripRequestApi.sendTripRequestEmail(email);
  },

  async [REQUEST_QUOTE](_, quote) {
    return await tripRequestApi.requestQuote(quote);
  },

  async [SAVE_TRIP_RECURRENCE](_, obj) {
    return await tripRequestApi.saveTripRecurrence(obj);
  },

  async [DOWNLOAD_TRIP_TICKET](_, obj) {
    return await tripRequestApi.downloadTripTicket(obj);
  },
  async [UPLOAD_TRIP_TICKET_TEMPLATE](_, obj) {
    return await tripRequestApi.uploadTripTicketTemplate(obj);
  },
  async [DOWNLOAD_TRIP_TICKET_TEMPLATE]() {
    return await tripRequestApi.downloadTripTicketTemplate();
  },
  async [DOWNLOAD_PERMISSION_SLIP](_, obj) {
    return await tripRequestApi.downloadPermissionSlip(obj);
  },
  async [UPLOAD_PERMISSION_SLIP_TEMPLATE](_, obj) {
    return await tripRequestApi.uploadPermissionSlipTemplate(obj);
  },
  async [DOWNLOAD_PERMISSION_SLIP_TEMPLATE]() {
    return await tripRequestApi.downloadPermissionSlipTemplate();
  },
  async [DOWNLOAD_TRIP_ESTIMATE](_, obj) {
    return await tripRequestApi.downloadTripEstimate(obj);
  },
};
