var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        width: "unset",
        persistent: _vm.persistent,
        "no-click-animation": "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-spacer"),
              !_vm.persistent
                ? _c(
                    "v-btn",
                    {
                      staticClass: "close-button",
                      attrs: { icon: "" },
                      on: { click: _vm.close },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "text-h6" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.choose(true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.yesLabel))]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.choose(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.noLabel))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }