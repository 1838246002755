var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "offset-x": _vm.isOffsetX,
        "offset-y": _vm.isOffsetY,
        "open-on-hover": _vm.isSubMenu,
        transition: _vm.transition,
        value: _vm.openMenu,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _vm.icon
                ? _c(
                    "v-btn",
                    _vm._g({ attrs: { color: _vm.color } }, on),
                    [_c("v-icon", [_vm._v(" " + _vm._s(_vm.icon) + " ")])],
                    1
                  )
                : _vm.isSubMenu
                ? _c(
                    "v-list-item",
                    _vm._g({ staticClass: "d-flex justify-space-between" }, on),
                    [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c("v-icon", [_vm._v(" mdi-chevron-right ")]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "text-capitalize",
                        attrs: {
                          disabled: _vm.disabled,
                          color: _vm.color,
                          text: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.openMenu = true
                          },
                        },
                      },
                      on
                    ),
                    [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                      _c("v-icon", [_vm._v("mdi-menu-down")]),
                    ],
                    1
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menuOpened,
        callback: function ($$v) {
          _vm.menuOpened = $$v
        },
        expression: "menuOpened",
      },
    },
    [
      _c(
        "v-list",
        { class: _vm.isSubMenu ? "submenu" : "" },
        [
          _vm.selectAllMenu.includes(_vm.name)
            ? _c(
                "v-list-item",
                {
                  staticClass: "selectall",
                  on: {
                    click: function ($event) {
                      return _vm.selectAllItems(_vm.menuItems)
                    },
                  },
                },
                [_vm._v(" Select All ")]
              )
            : _vm._e(),
          _vm._l(_vm.menuItems, function (item, index) {
            return [
              item.isDivider
                ? _c("v-divider", { key: index })
                : item.menu
                ? _c("sub-menu", {
                    key: index,
                    attrs: {
                      "is-offset-x": true,
                      "is-offset-y": false,
                      "is-open-on-hover": true,
                      "is-sub-menu": true,
                      "menu-items": item.menu,
                      name: item.name,
                    },
                    on: { "sub-menu-click": _vm.emitClickEvent },
                  })
                : _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { disabled: item.disabled },
                      on: {
                        click: function ($event) {
                          return _vm.emitClickEvent(item)
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ]),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }