var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8", attrs: { fluid: "" } },
    [
      !_vm.hideMenu
        ? _c(
            "v-toolbar",
            {
              staticClass: "mb-4 mt-2 px-4 menuBar",
              attrs: {
                "no-gutters": "",
                height: "50",
                elevation: "4",
                rounded: "",
              },
            },
            [_c("TableMenu")],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { class: [_vm.flipTable ? "flex-row-reverse" : ""] },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: !_vm.noForm ? "6" : "12" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm.title
                            ? _c("div", { staticClass: "text-h5" }, [
                                _vm._v(_vm._s(_vm.enhancedTitle)),
                              ])
                            : _vm._e(),
                          _c("v-spacer"),
                          _vm.baseTable
                            ? [
                                _c(
                                  "v-scroll-x-transition",
                                  [
                                    _vm.canEditSetupTables
                                      ? _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.selected.length,
                                                expression: "selected.length",
                                              },
                                            ],
                                            staticClass: "mx-2",
                                            attrs: { dark: "", color: "red" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "deleteItems",
                                                  _vm.selected
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.deleteLabel || "Delete"
                                                ) +
                                                " " +
                                                _vm._s(_vm.selected.length) +
                                                " " +
                                                _vm._s(_vm.title) +
                                                _vm._s(
                                                  _vm.selected.length > 1
                                                    ? "s"
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "v-scroll-x-transition",
                                  [
                                    _vm.canEditSetupTables
                                      ? _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.selected.length,
                                                expression: "selected.length",
                                              },
                                            ],
                                            staticClass: "mx-2",
                                            attrs: {
                                              dark: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "changeStatus",
                                                  _vm.selected,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Set as Active ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-scroll-x-transition",
                                  [
                                    _vm.canEditSetupTables
                                      ? _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.selected.length,
                                                expression: "selected.length",
                                              },
                                            ],
                                            staticClass: "mx-2",
                                            attrs: {
                                              dark: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "changeStatus",
                                                  _vm.selected,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Set as Inactive ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                          !_vm.hideCreate && _vm.canCreate
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    fab: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("createItem")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _vm.loading
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: "" },
                          })
                        : [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _vm.showSearch
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "px-3",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "mt-0 pt-0",
                                          attrs: {
                                            "append-icon": "mdi-magnify",
                                            label: "Search",
                                            "single-line": "",
                                            solo: "",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.search,
                                            callback: function ($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "rounded-md mx-2",
                                        attrs: { elevation: "2", outlined: "" },
                                      },
                                      [
                                        _c("v-data-table", {
                                          class: [
                                            `table`,
                                            _vm.pagination
                                              ? `showPagination`
                                              : ``,
                                            _vm.showSearch ? `showSearch` : ``,
                                          ],
                                          attrs: {
                                            "fixed-header": "",
                                            headers: _vm.headers,
                                            items: _vm.items,
                                            "item-key": "id",
                                            "item-class": _vm.rowClass,
                                            "show-select": !_vm.hideRowSelect,
                                            search: _vm.search,
                                            "hide-default-footer":
                                              !_vm.pagination,
                                            "disable-pagination":
                                              !_vm.pagination,
                                            "footer-props": {
                                              itemsPerPageOptions: [
                                                50, 100, 150,
                                              ],
                                            },
                                          },
                                          on: {
                                            "click:row": (row) =>
                                              _vm.handleRowSelected(row),
                                            "toggle-select-all":
                                              _vm.selectAllToggle,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              !_vm.me.is.superAdmin &&
                                              !_vm.me.is.transportationAdmin
                                                ? {
                                                    key: `header.data-table-select`,
                                                    fn: function () {
                                                      return undefined
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                              {
                                                key: `item.data-table-select`,
                                                fn: function ({
                                                  item,
                                                  isSelected,
                                                  select,
                                                }) {
                                                  return [
                                                    item.cannotDelete &&
                                                    item.cannotDeleteMessage
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  left: "",
                                                                                  color:
                                                                                    "red",
                                                                                },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-alert-circle "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.cannotDeleteMessage
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-simple-checkbox",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "ripple",
                                                                rawName:
                                                                  "v-ripple",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "lighten-4 pa-0",
                                                            attrs: {
                                                              value: isSelected,
                                                              readonly:
                                                                _vm.cannotDeleteItem(
                                                                  item
                                                                ),
                                                              disabled:
                                                                !_vm.canEditSetupTables ||
                                                                _vm.cannotDeleteItem(
                                                                  item
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return select(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                  ]
                                                },
                                              },
                                              _vm._l(
                                                _vm.$scopedSlots,
                                                function (_, name) {
                                                  return {
                                                    key: name,
                                                    fn: function (data) {
                                                      return [
                                                        _vm._t(
                                                          name,
                                                          null,
                                                          null,
                                                          data
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.noForm
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [_vm._t("itemForm")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }